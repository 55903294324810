<template>
  <div></div>
</template>

<script>
import { vuexOidcProcessSilentSignInCallback } from "vuex-oidc";
import { oidcSettings } from "@/../configuration";
export default {
  mounted() {
    vuexOidcProcessSilentSignInCallback(oidcSettings);
  },
};
</script>
